import $ from 'jquery';


$(document).on('turbo:load', function () {
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    const targetId = $(this).attr('href').substring(1);
    const targetElement = $('#' + targetId);
    if (targetElement.length) {
      $('html, body').animate({
        scrollTop: targetElement.offset().top
      }, 'smooth');
    }
  });
});
