import { Turbo } from "@hotwired/turbo-rails"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

require('./packs/theme')
require('./packs/header')
require('./packs/home_slider')
require('./packs/anchors')
require("./packs/hcaptcha");
